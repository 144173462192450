import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/da/analyse-konsulent",
  "Tag Management": "/da/tag-styring"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/tag-management"
);

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Hvad er et tag-styringssystem?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Et tag-styringssystem er et JavaScript-bibliotek til implementering og konfiguration af analyse- og marketingtags. HTML-tags, JavaScript-snippets eller tracking-pixels tilføjer funktioner til din hjemmeside eller app og kan installeres med blot et par klik. Efter at et tag er blevet udgivet til containeren, vil koden blive genereret og tilføjet til websidens side (DOM). Der implementeres ingen ny kildekode til serveren."
        }
      },
      {
      "@type": "Question",
      "name": "Hvilket problem løser et TMS?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Over tid er flere sociale medieplatforme kommet med deres egne tags (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, osv.), og hjemmesider er blevet fyldt med deres snippets. Som et resultat har virksomheder fået stigende omkostninger til webudvikling. Tag-managere løser disse problemer, fordi implementeringer kan skaleres og dermed spare tid, hvilket betyder, at virksomheder kan spare penge."
      }
    }
  ]
}
`;

const TagManagement = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tag Management forklaret (med ${currentYear} Sammenligning)`}
        description="Lær hvad tag management systemer gør, deres fordele, og hvorfor de blev så populære (inklusive en sammenligning af de nuværende TMS-leverandører)."
        lang="da"
        canonical="/da/tag-styring"
        image="tag-management-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="tag-management/tag-management-hero.png"
          alt="logoer af TMS platform leverandører ved siden af hinanden på mørk baggrund"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tag Management</H>
        <p className="baseline">
          Tag management nyder stigende popularitet og en voksende fællesskab. De fleste moderne websites kan ikke længere klare sig uden et tag management system, så nu bruger ca. <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% af alle websites en tag manager</a>.
        </p>
        <p>Men dette er en ret nylig marketingteknologi trend.</p>
        <p>
          I de seneste år er en transformerende ny sektor af tag management løsninger opstået. Selv i dag kommer nye løsninger på markedet på grund af ændrede regler og udviklende marketingteknologi.
        </p>
        <p className="baseline">
          En god illustration af den voksende interesse er den verdensomspændende søgning efter "Google Tag Manager" (GTM) - det mest populære tag management system (TMS) med en <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">markedsandel på 94% brug på hele internettet</a>:
        </p>
        <ImgScreenshot
          src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
          alt="Graf fra Google Trends der viser stigende interesse for Google Tag Manager"
          caption="Stigningen i søgninger efter 'Google Tag Manager' siden 2010 illustrerer den voksende interesse for tag management systemer. Ironisk nok blev den generelle term 'tag management' aldrig så populær som TMS platform leverandørerne."
          className="article-img"
        />
        <p className="baseline">Selvom den ekstreme vækst har stået på i et stykke tid, estimeres det, at brugen af tag managers vil <b>fortsætte med at vokse</b> med en årlig vækstrate på <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5.7% frem til 2024.</a></p>
        <H as="h2">Hvad er et tag management system?</H>
        <p>
          Et tag management system er et JavaScript bibliotek til at implementere og konfigurere analytics og marketing tags.
        </p>
        <p>
          HTML tags, JavaScript snippets eller tracking pixels tilføjer funktioner til din website eller app og kan installeres med blot få klik.
        </p>
        <H as="h3">Eksempler på tags</H>
        <ul>
          <li>Google Analytics tracking</li>
          <li>Video tracking</li>
          <li>Retargeting pixels (Facebook, Google Ads, LinkedIn etc.)</li>
          <li>Tilmeldingsformularer</li>
        </ul>
        <p>
          Tag implementeringer kan udføres gennem en grafisk brugerflade (GUI) for nem installation.
        </p>
        <p>Efter et tag er udgivet til containeren, vil koden blive genereret og tilføjet til websiden’s side (DOM). Ingen ny kildekode deployeres til serveren.</p>
        <p>Det mest populære TMS er <Link to="/da/hvad-er-google-tag-manager">Google Tag Manager</Link>. Det kommer med en række tag typer og skabeloner tilgængelige for tredjepartsudbydere, så du kan implementere tags med blot få klik.</p>
        <p>Implementeringen er baseret på 3 komponenter:</p>
        <ul>
          <li><strong>Tags</strong> (kode der skal tilføjes til siden)</li>
          <li><strong>Triggers</strong> (regler med betingelser der angiver, hvornår taggen skal affyres)</li>
          <li><strong>Variabler</strong> (dynamiske data der skal bruges i tags eller triggers)</li>
        </ul>

        <ImgScreenshot
          src="generic/google-tag-manager-overview-tab-en.png"
          alt="Google Tag Manager oversigtstab"
          className="article-img"
          caption="Oversigt over Google Tag Manager med panelet til venstre, der viser faner for konfiguration af tags, triggers og variabler."
        />
        <p>Konfigurationen udføres med simple formularer og inputfelter i stedet for at skulle kode det.</p>
        <p>Dette reducerer fejl og gør implementeringen af website tags <b>tilgængelig for digitale marketingfolk uden programmeringsbaggrund.</b></p>

        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Trigger konfiguration i GTM"
          className="article-img"
          caption="Trigger konfiguration i GTM. Det definerer, hvornår et tag udføres. Denne udføres, når et hvilket som helst HTML-element klikkes, der matcher CSS selektoren."
        />



        <H as="h2">Hvilket problem løser et TMS?</H>
        <p>
          Med web 2.0 og forbedrende marketingteknologi er nye muligheder for digitale marketingfolk opstået. De har gjort det muligt at analysere brugeradfærd for segmentering og bruge dataen til reklamekampagner.
        </p>
        <p>For at køre sådanne målrettede kampagner skulle flere <b>tags eller pixels</b> først placeres på websitet. Derefter kan tracking pixel kategorisere besøgende og deres adfærd for at målrette dem igen på Facebook eller Google Ads.</p>
        <p>Over tid har flere sociale medieplatforme lanceret deres egne tags (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, etc.) og websites er blevet fyldt med deres snippets.</p>
        <p>Det blev ikke ualmindeligt at se 20 til 40 individuelle tags i <code>{`<head>`}</code> sektionen af en side.</p>
        <p>Grundlaget for dette tagging system er et velkonfigureret <Link to="/da/event-sporing">event tracking setup</Link>. Alle relevante knapper skal udstyres med JavaScript funktioner. Når et element klikkes, vil JavaScriptet underrette den relevante marketing tag.</p>
        <p>Tags krævede hovedsageligt de samme brugerdata, men hvis du ville tilføje en anden dimension eller måleparameter blev det hurtigt en besværlig manuel opgave. Så administrationen af disse tags blev et endnu større problem.</p>
        <p>Så udviklere måtte bruge mange timer på at indsætte JavaScript kode i HTML elementer. At vide præcis hvordan koden skulle skrives eller hvilke variabler der kunne sendes med blev en ny organisatorisk opgave.</p>
        <p>Virksomheder der ønskede at køre kampagner ofte måtte opsætte event tracking på hver ny landingsside for at holde kampagnesporing kørende. At holde master tags, parametre og variabler vedligeholdt og opdaterede blev en ekstra opgave.</p>
        <p>Enhver eksperimentel idé om at spore en ny interaktion blev en indsats, der var svær at retfærdiggøre. Kodeændringer blev for arbejdskrævende.</p>
        <p>Kort sagt, ændringer og eksperimentering var hårdt og ufleksibelt. Men konverteringssporing skulle vedligeholdes for at måle kampagnepræstation. Ikke at måle det korrekt ville ellers gøre det svært at forbedre.</p>
        <p>Som resultat oplevede virksomheder en stigning i webudviklingsomkostninger. Problemet kunne kun løses med egenudviklede biblioteker, hvilket desværre førte til, at udviklingsteams løste problemet i siloer og genopfandt hjulet.</p>
        <p>Markedet var modent for innovation.</p>

        <p>Tag managers løser disse problemer, fordi implementeringer kan skaleres og dermed <b>spare tid</b>, hvilket betyder, at virksomheder kan <b>spare penge</b>.</p>
        <p>Ironisk nok implementeres et tag management system også gennem en <Link to="/da/wiki-analytics/container-maerke">container tag</Link> på alle sider. Dog kan alle senere tags tilføjes via TMS selv.</p>
        <p>Tag manager koden skal tilføjes og deployeres kun én gang i starten, og alle efterfølgende tags implementeres gennem tag manager værktøjets grafiske brugerflade.</p>

        <ImgScreenshot
          src="tag-management/matomo-trigger-types.png"
          alt="Liste over tilgængelige trigger typer i Matomo Tag Manager værktøjet"
          className="article-img"
          caption="Typer af triggers i Matomo. Triggers definerer, hvornår en bestemt kode skal udføres, og der er en lang liste at vælge fra - ingen kodningsfærdigheder krævet."
        />

        <p>Arbejdsbyrden reduceres, og proceduren <b>gøres meget enklere</b>.</p>
        <p>Gennem den forenklede implementering kan <Link to="/da/google-tag-manager-konsulent">tag management konsulenter</Link> derefter konfigurere dataindsamlingen af tracking tags. Denne nye rolle i organisationen fokuserer på JavaScript programmering udelukkende for web analytics og digitale marketingtjenester.</p>
        <p>Hver regel og hvert datapunkt kan nu bruges på tværs af flere tags og endda på tværs af flere domæner.</p>
        <p>En tag management platform har mange fordele. Så mange faktisk, at du sjældent hører nogen klager.</p>

        <H as="h2">Fordele</H>
        <p>
          En af de store fordele ved tag management systemer er, at en teknisk marketingmedarbejder kan implementere marketing tags uden at være afhængig af en udvikler.
        </p>

        <p>Den grafiske brugerflade gør det nemt for en digital marketingmedarbejder at udføre grundlæggende implementeringer, som ellers ville være for svære i rå kode.</p>

        <p>Som et <b>eksempel</b> her er listen over marketingværktøjer og analytics tags du kan installere med <b>Matomo Tag Manager</b>:</p>
        <ImgScreenshot
          src="tag-management/matomo-tag-templates.png"
          alt="Liste over tag typer i Matomo"
          className="article-img"
          caption="Tag typeliste fra Matomo - Tags kommer med en foruddefineret skabelon, så kun formularfelter skal udfyldes for implementering."
        />
        <p>Nedenfor ser du en liste over GTM's tilgængelige <b>tag muligheder</b> (<a href="https://support.google.com/tagmanager/answer/6106924?hl=da" target="_blank" noopener>fuldstændig liste over understøttede tags</a>). Til sammenligning har GTM de fleste tag skabeloner tilgængelige samt <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>brugerdefinerede skabeloner</a>.</p>
        <ImgScreenshot
          src="tag-management/gtm-tag-types.png"
          alt="Liste over tags fra GTM"
          className="article-img"
          caption="Liste over tags fra GTM - Jeg måtte forkorte tag listen af pladshensyn. Der er endnu flere tag skabeloner, hvis du kigger under brugerdefinerede skabeloner."
        />

        <p className="baseline">Da tag implementering sker på sekunder, og et marketingteam ikke længere behøver at vente på kode implementering på serveren, sparer brugen af et TMS <b>tid, omkostninger og reducerer overhead projektledelse</b>.<br />Dette bekræftes i en rapport fra Econsultancy i 2015, hvor <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% af marketingfolk sagde, at brugen af et TMS hjælper med at reducere omkostninger</a>.</p>
        <p>Med denne fleksibilitet er agile arbejdsgange mulige, der tillader gradvise kampagneoptimeringer, tests og eksperimenter.</p>
        <p>Digitale marketingkampagner kan køre i flere varianter, mens de målretter mere specifikke grupper, hvilket øger konverteringerne og giver et godt fundament for at lære, hvordan grupper reagerer på tilpassede budskaber.</p>
        <p>Virksomheder, der allerede har datasiloer tilgængelige, som databaser, CRM'er og API'er, kan bruge et <Link to="/da/data-lag">data lag</Link> for at gøre dataen tilgængelig til kampagnesporingsregler.</p>
        <p>En anden undervurderet fordel er de tilgængelige indstillinger for arbejdsområder, miljøer og brugerrettigheder, der danner en ledelsesløsning for at give adgang til bureauer eller eksterne konsulenter. Adgang kan gives på sekunder for at lade nogen arbejde på et individuelt tag eller hele tagging planen.</p>

        <p>Sammenfattende er <b>fordelene ved tag management</b> som følger:</p>
        <ul>
          <li>Omkostningsbesparelser på grund af lavere implementeringsomkostninger</li>
          <li>Hurtige implementeringer</li>
          <li>Øget fleksibilitet, da ændringer og rettelser kan implementeres på sekunder</li>
          <li>Robust og stabil integration af eksterne datakilder</li>
          <li>Mulighed for nemt at tildele forskellige brugeradgangsrettigheder til eksterne partnere</li>
        </ul>
        <H as="h3">Ulemper</H>
        <p>Der er en fundamentalt begrundet bekymring om, at en <b>websites indlæsningshastighed sænkes</b> ved brug af et TMS.</p>
        <p>Dette er dog kun tilfældet i meget begrænset omfang på grund af asynkron indlæsning af tags.</p>
        <p className="baseline">Websites med et velkonfigureret TMS <b>indlæses normalt hurtigere</b> end hvis tags blev implementeret direkte i kilden. En rapport fra Econsultancy fra 2015 angiver, at <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% af TMS brugere oplever hurtigere webpræstation</a>.</p>
        <p>Da optimering af indlæsningshastighed ofte bliver brutalt forsømt og på grund af den minimale hastighedstab forårsaget af et TMS, er der normalt langt mere effektive metoder til at forbedre indlæsningstiden end at undlade at bruge tag management værktøjer helt.</p>

        <p>Og nu, hvem ville du tro, havde nok interesse i at lette digital reklame og konverteringssporing til at udvikle en løsning gratis? Det må være en virksomhed, der drager fordel af, at andre kører digital reklame 😉.</p>

        <H as="h2">Platform Sammenligning</H>

        <p>Her er en sammenligning af de vigtigste platforme på tag management system markedet:</p>

        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Pris</th>
              <th>Data<br />ejerskab</th>
              <th>Tilgængelighed<br />af Eksperter</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>GTM (gratis)</b></td>
              <td>gratis</td>
              <td>nej</td>
              <td>høj</td>
            </tr>
            <tr>
              <td><b>GTM 360</b></td>
              <td>høj</td>
              <td>ja</td>
              <td>høj</td>
            </tr>
            <tr>
              <td><b>Adobe Launch</b></td>
              <td>høj</td>
              <td>ja</td>
              <td>mellem</td>
            </tr>
            <tr>
              <td><b>Tealium iQ</b></td>
              <td>mellem</td>
              <td>ja</td>
              <td>lav</td>
            </tr>
            <tr>
              <td><b>Matomo</b></td>
              <td>lav</td>
              <td>ja</td>
              <td>lav</td>

            </tr>
            <tr>
              <td><b>Piwik</b></td>
              <td>mellem</td>
              <td>ja</td>
              <td>lav</td>


            </tr>
          </tbody>
        </table>
        <p>For virksomheder falder valget oftest på premium platforme som <b>Google Tag Manager 360, Adobe Launch</b> (efterfølger af Adobe DTM) eller <b>Tealium iQ</b>, fordi de opfylder virksomhedernes privatlivskrav til kundedata.</p>
        <p>
          Prisen på en premium TMS fra Google 360, Adobe eller Tealium kan ikke defineres præcist, fordi de sælges i konfigurerbare virksomhedspakker sammen med andre analysetjenester.
        </p>

        <p>
          <u>Priserne er derfor kun omtrentlige og bør betragtes som en grov orientering</u>. Individuelle priser er kendt for at variere.
        </p>

        <p>Hvis du gerne vil se, hvordan <b>Tealium Tag Manager</b> ser ud, så se deres demovideo:</p>
        <YouTube id="HF1ZiPQYP6M" />
        <br />
        <p>Ligeledes, her er en video, hvor du kan se <b>Adobe Launch</b> interface og hvordan en sideindlæsningsregel opsættes i det:</p>
        <YouTube id="JR2mRfKU18Y" />
        <br /><br />
        <p>Det mest populære tag management værktøj er dog <a href="https://tagmanager.google.com/" target="_blank">gratisversionen af Google Tag Manager</a>.</p>
        <H as="h2">Almindelige anvendelsestilfælde</H>

        <p>Dataindsamling til webanalyse, digital markedsføring eller data science forskning er de mest almindelige anvendelser for tag management systemer.</p>


        <H as="h3">Webanalyse</H>
        <p>Webanalyse systemer som Google Analytics er hurtige at opsætte og nemmere at skalere på tværs af flere domæner ved brug af et TMS. Enhver efterfølgende tilføjelse eller ændring implementeres hurtigt og kræver færre ressourcer, hvilket er grunden til, at tag management er blevet standard for digital analyse i de senere år.</p>
        <p><b>Konverteringsrate optimering (CRO)</b> er også stort set baseret på metrics fra et analysetag og er derfor stærkt forbundet.</p>

        <H as="h3">Digital Markedsføring</H>
        <p>Reklamer på platforme som Facebook, Twitter, Instagram og Google Ads er populære trafikanskaffelseskanaler. Digital reklame gennem retargeting med bannere eller Adwords er derfor det mest almindelige anvendelsestilfælde.</p>
        <p>Sådan marketing teknologi er baseret på tags, der kræver datapunkter fra en eller flere digitale ejendomme for at skabe brugersegmenter. Derfor er det en forudsætning at administrere tags på en kontrollerbar og skalerbar måde.</p>
        <p><b>Affiliate marketing</b> er baseret på at tildele konverteringer til trafikhenvisninger og er også stærkt afhængig af cookielogik implementeret gennem tag managers.</p>

        <H as="h3">Data Science</H>
        <p>Med AI's fremkomst steg behovet for data, da datamodeller skal trænes på store mængder data.</p>
        <p>Enhver data, der ikke kan skrabes fra statiske sider, samles normalt med en tag management løsning. Derfra sendes det videre til en datalagring, der er tilgængelig til datamodellering.</p>

        <H as="h2">Tag Management Rådgivning</H>
        <p>Rådgivningstjenester for tag management opstod samtidig med væksten på markedet: Yderligere udbydere opstod med nye løsninger og prisniveauer, der skulle tages i betragtning.</p>
        <p>Desuden ændrede <b>brugerdata beskyttelseslovgivningen i EU</b> (GDPR) kravene til konfigurationer og gav privatlivsfokuserede udbydere et skub fremad.</p>
        <p>Rådgivning for tag management koncentrerer sig om platformsvalg, implementering, validering og vedligeholdelse af systemet.</p>
        <p>Tag Management konsulenter ansættes normalt fra bureauer, der fokuserer på digital reklame, webanalyse, eller de er <Link to="/da/google-tag-manager-konsulent">freelance konsulenter</Link>.</p>

        <H as="h2">Er der tilfælde, hvor et TMS ikke er den rette løsning?</H>
        <p><b>Et tag management system er det rette valg for de fleste websites og apps</b>. Kun websites, der opererer i sektorer med specifikke krav til databeskyttelse, bør nøje overveje, hvordan infrastrukturen er designet for at overholde databeskyttelseslovgivningen.</p>
        <p>Dette er dog normalt ikke et argument imod brugen af et TMS, men snarere et argument for at vælge det rigtige.</p>
        <p>En anden overvejelse kan være websitets ydeevne.</p>
        <p>Hvis et TMS ikke er korrekt opsat, kan det sænke en website. Nicher, hvor reklame spiller en vigtig rolle, kan have svært ved langsomme sideindlæsningstider. At spare et par hundrede millisekunder kan tilføje værdi, men det er tvivlsomt, om fordelene ved hurtigere indlæsningstid retfærdiggør de højere omkostninger ved at administrere tracking pixels.</p>
        <p>Afhængigt af de interne ressourcers kapabiliteter er det muligt at køre en website og digital markedsføring succesfuldt uden et TMS, men det er normalt undtagelser.</p>
        <p>Enhver website, der ikke har et ekstremt antal sider eller nichekrav til tredjeparts tags, burde kunne kontrollere deres HTML og JavaScript tags med et TMS.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default TagManagement;
